<template>
  <!-- 农村产改 -->
  <div class="production-change">
    <div class="center-container">
      <div class="left-content">
        <div class="s-title s-title-b">
          <span class="decoration"></span>
          <span class="title">农村产改</span>
        </div>
        <div class="change">
          <el-tree
            ref="tree"
            class="my-tree"
            :data="treeData"
            :props="defaultProps"
            node-key="xzqbh"
            :indent="30"
            :default-expanded-keys="defaultExpandedKey"
            :default-expand-all="false"
            :accordion="true"
            @node-click="handleNodeClick"
            >
            <span class="custom-tree-node" slot-scope="{ node, }">
              <span :title="node.label">{{ node.label }}</span>
            </span>
          </el-tree>
        </div>
      </div>
      <div class="right-content">
        <div class="s-title s-title-b">
          <span >当前位置：</span>
          <span >农村产改</span> >
          <span>{{title}}</span>
        </div>
        <div class="info">
          <div class="query-card">
            <el-form class="queryform" :model="form" label-position="left" inline ref="form">
              <el-form-item label="关键字" prop="keyword">
                <el-input style="width: 290px;" v-model="form.keyword" placeholder="请输入关键词" clearable></el-input>
              </el-form-item>
              <div class="button">
                <el-form-item>
                  <el-button class="query-btn" type="primary" @click="query">查询</el-button>
                  <el-button class="query-btn" type="primary" @click="clear">清空</el-button>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="table-card">
            <div class="docs">
              <div class="docs-item" v-for="(item, index) in msgs" :key="index" @click="showDetail(item)">
                <span>
                  <img src="imgs/icon-decoration.png" alt="">
                  <span>{{item.title}}</span>
                </span>
                <span style="color: #a5a5a5;">{{item.createTime}}</span>
              </div>
            </div>
            <div class="pagenation" v-if="total > 0">
              <el-pagination
                background
                layout="total, slot, prev, pager, next, slot"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRegionList } from '../../api/projectManage'
import { getFileLists } from '../../api/development'
export default {
  data() {
    return {
      defaultProps: {
        label: 'xzqmc',
        children: 'list',
        value: 'xzqbh',
      },
      defaultExpandedKey: [],
      treeData: [
      ],
      currentType: {},
      form: {},
      currentPage: 1,
      pageSize: 10,
      total: 0,
      title: '',
      msgs: []
    }
  },
  mounted() {
    this.getRegions();
  },
  methods: {
    async getRegions() {
      // 获取信息
      let res = await getRegionList();
      let { status,data } = res;
      if(status) {
        this.treeData = data;
        this.$nextTick(() => {
          this.initData();
        })
      }
    },
    initData() {
      // 初始化
      let expandId = this.treeData[0].xzqbh;
      // let checkId = this.treeData[0].list[0].xzqbh;
      this.defaultExpandedKey.push(expandId);
      // this.$refs.tree.setCurrentKey(checkId);
      this.handleNodeClick(
        this.treeData[0],
        this.$refs.tree.getNode(expandId)
      );
    },
    async getNews() {
      // 获取数据
      let params = {
        current: this.currentPage,
        size: this.pageSize,
        articleFid: 4,
        articleSid: this.currentParant.xzqbh,
        articleTid: this.currentType.xzqbh,
        title: this.form.keyword
      }
      let res = await getFileLists(params);
      let {code, data} = res;
      if(code == 0) {
        this.msgs = data.records;
        this.total = data.total;
      }
    },
    handleNodeClick(obj,node,self) {
      // 树节点点击事件
      this.title = obj.xzqmc;
      if(node.level === 1) {
        this.currentParant = obj;
      } else {
        this.currentParant = node.parent.data;
        this.currentType = obj;
      }
      console.log(node, 'node22')
      this.getNews();
    },
    query() {
      // 查询
      this.currentPage = 1;
      this.initData();
    },
    clear() {
      this.form = {};
      this.query();
    },
    showDetail(item) {
      // 详情
      this.$router.push({name: 'newsPage',query: {c: item.id}})
    }
  }
}
</script>

<style lang="less" scoped>
.production-change {
  // background: #fff;
  padding-top: 20px;
  padding-bottom: 72px;
  text-align: left;
  .search {
    .el-button {
      width: 107px;
      height: 35px;
      padding: 0 6px;
      line-height: 35px;
      border-radius: 0;
      img {
        vertical-align: middle;
        margin-right: 5px;
        margin-bottom: 3px;
      }
    }
  }
  .table-card {
    padding: 10px;
  }
  .docs {
    // height: 640px;
    margin-bottom: 8px;
    // overflow-y: auto;
    // margin-top: 25px;
    .docs-item {
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 56px;
      color: #333333;
      padding-right: 8px;
      margin-bottom: 14px;
      border-bottom: 1px solid #c9c9c9;
      cursor: pointer;
      img {
        margin-right: 9px;
      }
    }
  }
  .pagenation {
    text-align: right;
  }
  .query-btn {
    width: 126px;
    height: 34px;
    padding-top:9px;
  }
  .query-btn:last-child {
    width: 70px;
  }
}
</style>

<style lang="less">
.my-tree {
  /*/ / 动画取消*/
  .el-tree-node__expand-icon.expanded {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  /*/ / 收起*/
  .el-icon-caret-right:before {
    font-size: 12px;
    content: url('../../assets/imgs/expand.png');                   /*具体的图标*/
  }
  /*/ / 展开*/
  .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
    font-size: 12px;
    content: url('../../assets/imgs/unfolded.png');                    /*具体的图标*/
  }
  // 最后一个叶子节点不展示图标
  .el-tree-node__expand-icon.is-leaf.el-icon-caret-right:before {
    font-size: 18px;
    content: '';                   /*具体的图标*/
  }

  .custom-tree-node {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.right-content {
  .pagenation {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      border-radius: 0;
    }
  }
}
</style>
